import { Box, Accordion } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { SEO } from "../components/seo"
import { AccordionItem } from "../components/accordionItem"

const Results: FunctionComponent<{ data: any }> = ({ data }) => {
  const results = data.allPrismicRaces.edges

  const archivedRaces = data.allPrismicRaces.nodes.filter(({ data }) => {
    if (data.datetime) {
      const year = data.datetime.split(".")[0]
      const month = data.datetime.split(".")[1]
      const day = data.datetime.split(".")[2]

      return new Date() > new Date(year, month - 1, day)
    }
  })

  return (
    <Layout>
      <Container>
        <SEO title="Eredmények" />
        <Title text="Eredmények" />

        <Accordion allowToggle>
          {archivedRaces?.map((race: any, index: number) => {
            console.log(race)
            const { data } = race
            return (
              <AccordionItem
                key={index}
                title={data.title?.text}
                content={data.result.html}
              />
            )
          })}
        </Accordion>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArchiveRacesResults {
    allPrismicRaces(sort: { fields: data___title___html }) {
      nodes {
        data {
          datetime(formatString: "YYYY. MM. DD.")
          title {
            text
          }
          result {
            html
          }
        }
      }
    }
  }
`

export default Results
